import React, { useState, useEffect, useMemo, useRef } from "react";
import "../../styles/Blogs.css";
import innerBlog from "../../Assets/average scores accepted.png";
import FrenchImg from "../../Assets/News/french proficiency.png";
import Right from "../../Assets/News/application fee hiked.png";
import Working from "../../Assets/News/working hours increased.png";
import Changes from "../../Assets/News/processing time and backlog.jpg";
import Major from "../../Assets/News/Major provinces issue PNP invitations.jpg";
import { useMobile } from "../globalComponents/MobileContext/IsMobileContext";
import { IoIosArrowDroprightCircle } from "react-icons/io";
const AverageLanguageScores = () => {
  const [showBackToTop, setShowBackToTop] = useState(false);
  const bubbleRef = useRef();
  const { vw } = useMobile();

  useEffect(() => {
    const handleScroll = () => {
      // Check if the Home > Blogs > Inner Blogs link is visible
      const innerBlogsLink = document.querySelector(".title-heading");
      if (innerBlogsLink) {
        const bounding = innerBlogsLink.getBoundingClientRect();
        if (bounding.top >= 0 && bounding.bottom <= window.innerHeight) {
          setShowBackToTop(false);
        } else {
          setShowBackToTop(true);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scrolling behavior
    });
  };
  function useIsInViewport(ref) {
    const [isIntersecting, setIsIntersecting] = useState(false);

    const observer = useMemo(
      () =>
        new IntersectionObserver(([entry]) =>
          setIsIntersecting(entry.isIntersecting)
        ),
      []
    );

    useEffect(() => {
      observer.observe(ref.current);

      return () => {
        observer.disconnect();
      };
    }, [ref, observer]);

    return isIntersecting;
  }

  const makeDivRelative = useIsInViewport(bubbleRef);

  const newsData = [
    {
      id: 1,
      title: "Major Provinces Issue PNP Invitations",
      description:
        "  Much to the delight of candidates who had applied for PNP in British Columbia, Manitoba and Quebec,",
      image: Major,
      date: "7 JUN. 2024",
      path: "/latest-news/major-provinces-issue-pnp-invitations",
    },
    {
      id: 2,
      title: "French Proficiency Leads Category-Based Selections",
      description:
        "French language proficiency will now be at the forefront of category-based selections in express entry draws in 2024.",
      image: FrenchImg,
      date: "26 APR. 2024",
      path: "/latest-news/french-proficiency-leads-category-based-selection",
    },

    {
      id: 3,
      title: "Working hours Increased for Students in Canada",
      description:
        "As of April 29th 2024, working hours for students will be increased; for both during academic session and academic breaks.",
      image: Working,
      date: "29 APR. 2024",
      path: "/latest-news/working-hours-increased-for-students-in-canada",
    },
    {
      id: 4,
      title: "Changes in Federal Business Programs to tackle Backlog issue",
      description:
        "Changes have been announced to the Start-up visa and Self-Employed Persons programs to manage backlogs ",
      image: Changes,
      date: "29 APR. 2024",
      path: "/latest-news/changes-in-federal-business-programs-to-tackle-backlog-issue",
    },
  ];

  //  const keywords = [
  //   {
  //     title: "New Year Edition",
  //   },
  //   {
  //     title: "New Year Edition",
  //   },
  //   {
  //     title: "New Year Edition",
  //   },
  //   {
  //     title: "New Year Edition",
  //   },
  //   {
  //     title: "New Year Edition",
  //   },
  //   {
  //     title: "New Year Edition",
  //   },
  // ];
  // const Keywords = ({ title }) => {
  //   return (
  //     <div className="post-card-keywords">
  //       <div>
  //         <h1>{title}</h1>
  //       </div>
  //     </div>
  //   );
  // };

  const innerPosts = [
    {
      title:
        "Averaged Language Scores now Accepted under Express Entry for Disabled Candidates",
      description: (
        <p>
          Averaged language scores generated by the respective language
          organisation will now be accepted under Express Entry only for
          candidates living with physical or mental disability.
          <br />
          <br />
          Under Express Entry profile and electronic Application for permanent
          residence (eAPR), candidates are supposed to submit scores for each of
          their linguistic skill areas which are reading, writing, listening and
          speaking.
          <br />
          <br />
          A reform has been made for candidates with mental or physical
          disabilities that may preclude them from performing in the language
          assessments. Disabled candidates can now be exempted from providing
          scores received in up to 3 out of 4 language skill areas but they will
          still need to present their averaged score which is generated by their
          respective language testing organisation. It is essential for the
          candidate to submit medical documentation to the language testing
          agency in order to avail the benefits.
          <br />
          <br />
          Candidates are instructed to get their scores averaged by the testing
          organisation and submit them in their eAPR and Express Entry Profile.
          There is an exception for foreign nationals who have taken the IELTS
          test (International English Language Testing System) whose scores will
          already be averaged by the agency itself.
          <br />
          <br />
          Furthermore, post submission, IRCC officials will cross check the
          validity of the applicant’s score using the department's averaging
          tool.
          <br />
          <br />
          <span>WHAT ARE THE OTHER EXEMPTIONS? </span>
          <br />
          <br />
          Other accommodations provided to candidates with disabilities include
          modified test material, extra time for completion of test and
          assistive technology (text to speech system, Braille display and so
          on).
          <br />
          <br />
          While most IRCC designated testing agencies follow this policy change,
          organisations like Pearson Canada Inc. (PTE Core) and Test de
          connaissance du français pour le Canada (TCF Canada) do not provide
          any exemptions on language evaluation.
          <br />
          <br />
          This reform accommodates physically or mentally disabled foreign
          nationals and facilitates their application for permanent residence in
          Canada.
        </p>
      ),

      image: innerBlog,
      date: "29 APR. 2024",
    },
  ];
  const InnerBlog = ({ title, description, image, date }) => {
    return (
      <div className="post-card-inner">
        <h1>{title}</h1>
        <p3>{date}</p3>
        <br /> <br />
        <img alt="" src={image} />
        <p2>{description}</p2>
      </div>
    );
  };

  const latestNews = [
    {
      title: "Right of Permanent Resident fee increased",
      image: Right,
      path: "/latest-news/right-of-permanent-resident-fee-increased",
    },
    {
      title: "Changes in Federal Business Programs to tackle Backlog issue",
      image: Changes,
      path: "/latest-news/changes-in-federal-business-programs-to-tackle-backlog-issue",
    },
    {
      title: "Working hours Increased for Students in Canada",
      image: Working,
      path: "/latest-news/working-hours-increased-for-students-in-canada",
    },
  ];
  const LatestNews = ({ title, image, path }) => {
    return (
      <div className="post-card-recent">
        <a href={path}>
          <img alt="" src={image} />
        </a>
        <div className="title-recent">
          <a href={path}>
            <h1 className="line-clamp-2">{title}</h1>
          </a>
          <p2
            style={{
              color: "#009889",
              display: "flex",
              gap: "4px",
              fontSize: "small",
            }}
          >
            Know more{" "}
            <IoIosArrowDroprightCircle style={{ alignSelf: "center" }} />
          </p2>
        </div>
      </div>
    );
  };

  return (
    <>
      <div style={{ background: "#FFF" }}>
        <div className="AboutUsHeader">
          <p className="link">
            <a href="/">Home</a>
            {" > "}
            <a href="/latest-news">Newsroom</a>
            {" > "} Averaged Language Scores now Accepted under Express Entry
            for Disabled Candidates
          </p>
          <div className="header-content">
            <h1 className="heading-about">Welcome to Our Newsroom</h1>
          </div>
        </div>
        <div className="mt-[2.5rem]">
          <div className="left-blog">
            {vw > 1250 && (
              <div>
                <h1 className="title-heading">Recent News</h1>
                <div className="blog-card-container">
                  <div className="blog-card-recent">
                    {latestNews.map((post, index) => (
                      <LatestNews key={index} {...post} />
                    ))}
                  </div>
                </div>
              </div>
            )}

            <div className="blog-cards-container">
              <div className="blog-cards">
                {innerPosts.map((post, index) => (
                  <InnerBlog key={index} {...post} />
                ))}
              </div>
            </div>
          </div>
          <div className="similar-blog">
            <div className="similar-div-heading">
              <h1 className="title-heading">Similar News</h1>
              <a href="/latest-news">All Articles</a>
            </div>
            <br />
          </div>
          <div className="news-card-container">
            <div className="news-card-upper">
              {newsData.map((news) => (
                <a key={news.id} href={news.path}>
                  <div className="news-upper-card">
                    <img
                      style={{ cursor: "pointer" }}
                      alt=""
                      src={news.image}
                    />
                    <div className="content-card">
                      <div>
                        <p3>{news.date}</p3>

                        <a href={news.path}>
                          <h1>{news.title}</h1>
                        </a>

                        <p2>{news.description} </p2>
                      </div>

                      <a
                        style={{
                          display: "flex",
                          gap: "0.3rem",
                        }}
                        href={news.path}
                      >
                        Read more{" "}
                        <IoIosArrowDroprightCircle
                          style={{ alignSelf: "center" }}
                        />
                      </a>
                    </div>
                  </div>
                </a>
              ))}
            </div>
          </div>
        </div>
        {showBackToTop && (
          <div
            className={makeDivRelative ? "relativeDiv" : "backToTop"}
            onClick={scrollToTop}
          >
            BACK TO TOP
          </div>
        )}{" "}
      </div>

      <div ref={bubbleRef}></div>
    </>
  );
};

export default AverageLanguageScores;
