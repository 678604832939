import React, { useState, useEffect, useMemo, useRef } from "react";
import "../../styles/Blogs.css";
import innerBlog from "../../Assets/french proficiency.png";
//import RecentBlogs from "../Pages/Blogs/RecentBlogs.js";
import Averaged from "../../Assets/News/average scores accepted.png";
import Right from "../../Assets/News/application fee hiked.png";
import Working from "../../Assets/News/working hours increased.png";
import Changes from "../../Assets/News/processing time and backlog.jpg";
import Major from "../../Assets/News/Major provinces issue PNP invitations.jpg";
import { useMobile } from "../globalComponents/MobileContext/IsMobileContext";
import { IoIosArrowDroprightCircle } from "react-icons/io";
const Table = () => {
  const data = [
    { name: "French proficiency", ct: "30%" },
    { name: "Healthcare occupations", ct: "15%" },
    { name: "STEM occupations", ct: "25%" },
    { name: "Trade occupations", ct: "5%" },
    { name: "Transport occupations", ct: "3%" },
    { name: "Agriculture and agri-food occupations", ct: "0.5%" },
  ];

  return (
    <table>
      <thead>
        <tr>
          <th style={{ width: "50%" }}>CATEGORY</th>
          <th style={{ width: "50%" }}>CATEGORY TARGET</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr key={index}>
            <td>{item.name}</td>
            <td>{item.ct}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
const FrenchProficiency = () => {
  const { vw } = useMobile();
  const [showBackToTop, setShowBackToTop] = useState(false);
  const bubbleRef = useRef();

  useEffect(() => {
    const handleScroll = () => {
      // Check if the Home > Blogs > Inner Blogs link is visible
      const innerBlogsLink = document.querySelector(".title-heading");
      if (innerBlogsLink) {
        const bounding = innerBlogsLink.getBoundingClientRect();
        if (bounding.top >= 0 && bounding.bottom <= window.innerHeight) {
          setShowBackToTop(false);
        } else {
          setShowBackToTop(true);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scrolling behavior
    });
  };
  function useIsInViewport(ref) {
    const [isIntersecting, setIsIntersecting] = useState(false);

    const observer = useMemo(
      () =>
        new IntersectionObserver(([entry]) =>
          setIsIntersecting(entry.isIntersecting)
        ),
      []
    );

    useEffect(() => {
      observer.observe(ref.current);

      return () => {
        observer.disconnect();
      };
    }, [ref, observer]);

    return isIntersecting;
  }

  const makeDivRelative = useIsInViewport(bubbleRef);

  //   const keywords = [
  //   {
  //     title: "New Year Edition",
  //   },
  //   {
  //     title: "New Year Edition",
  //   },
  //   {
  //     title: "New Year Edition",
  //   },
  //   {
  //     title: "New Year Edition",
  //   },
  //   {
  //     title: "New Year Edition",
  //   },
  //   {
  //     title: "New Year Edition",
  //   },
  // ];
  // const Keywords = ({ title }) => {
  //   return (
  //     <div className="post-card-keywords">
  //       <div>
  //         <h1>{title}</h1>
  //       </div>
  //     </div>
  //   );
  // };

  const newsData = [
    {
      id: 1,
      title: "Right of Permanent Resident fee increased",
      description:
        "Canada's right of permanent residence fee (RPRF) has been significantly increased, with a substantial hike of 12%.",
      image: Right,
      date: "30 APR. 2024",
      path: "/latest-news/right-of-permanent-resident-fee-increased",
    },
    {
      id: 2,
      title:
        "Averaged Language Scores now Accepted under Express Entry for Disabled Candidates",
      description:
        "Averaged language scores generated by the respective language ",
      image: Averaged,
      date: "29 APR. 2024",
      path: "/latest-news/averaged-language-scores-now-accepted-under-express-entry-for-disabled-candidates",
    },

    {
      id: 3,
      title: "Major Provinces Issue PNP Invitations",
      description:
        "  Much to the delight of candidates who had applied for PNP in British Columbia, Manitoba and Quebec,",
      image: Major,
      date: "7 JUN. 2024",
      path: "/latest-news/major-provinces-issue-pnp-invitations",
    },
    {
      id: 4,
      title: "Changes in Federal Business Programs to tackle Backlog issue",
      description:
        "Changes have been announced to the Start-up visa and Self-Employed Persons programs to manage backlogs ",
      image: Changes,
      date: "29 APR. 2024",
      path: "/latest-news/changes-in-federal-business-programs-to-tackle-backlog-issue",
    },
  ];
  const innerPosts = [
    {
      title: "French Proficiency Leads Category-Based Selections",
      description: (
        <p>
          French language proficiency will now be at the forefront of
          category-based selections in express entry draws in 2024.
          <br />
          <br />
          According to the latest Access to Information request (ATIP) made by
          Carry Immigration, 78.5% Invitations to Apply (ITAs) will be issued to
          category based draws. Remaining 21.5% ITAs will be issued in general
          Express Entry draws.
          <br />
          <br />
          The ATIP also stated that candidates within the French language
          proficiency category will avail 30% of the ITAs. Followed by STEM
          category (Science, Technology, Engineering and Math) with 25% ITAs and
          healthcare category at 15%.
          <br />
          <br />
          Here’s a breakdown of the ITA share of each category;
          <br />
          <br />
          <Table />
          <br />
          The category target has been increased so that the Immigration,
          Refugees and Citizenship Canada (IRCC) can meet its mandatory target
          of 4.4% francophone immigrants i.e French speaking immigrants. Marc
          Miller, the immigration minister, stated that this target will rise
          from 4.4% to 6% in 2024.
          <br />
          <br />
          As per a Labour Market Information Council report(2021), there is a
          shortage of French speaking qualified edskilled workers, thereby
          making it difficult to fill positions that require bilingualism. It
          was also noted that language ability largely affects a candidate's
          success in the labour force in Canada. French speaking economic
          immigrants had higher employment opportunities.
          <br />
          <br />
          This reform also benefits the French speaking minorities allowing them
          to access various services in their first language.
          <br />
          <br />
          <span>WHAT IS CATEGORY BASED EXPRESS ENTRY?</span>
          <br />
          <br />
          Category based selections were first introduced in May 2023 by the
          then immigration minister Sean Fraser. This category based selection
          was to aid the labour shortage by filling the vacant positions in
          Canada.
          <br />
          <br />
          This has allowed IRCC to focus on candidates who are skilled in the
          specified categories:
          <br />
          <br />
          <ul>
            <li>French proficiency </li>
            <li>Healthcare</li>
            <li>STEM</li>
            <li>Transportation</li>
            <li>Trade</li>
            <li>Agriculture and agri-food</li>
          </ul>
          <br />
          This made the immigration system of Canada more responsive to the
          country's socioeconomic needs. It allowed them to choose immigrants
          that could prove valuable to the requirements of the country.
          <br /> <br />
          <a href="/blogs/what-is-category-based-selection-everything-you-need-to-know">
            <span>Learn more</span>
          </a>
        </p>
      ),

      image: innerBlog,
      date: "26 APR. 2024",
    },
  ];
  const InnerBlog = ({ title, description, image, date }) => {
    return (
      <div className="post-card-inner">
        <h1>{title}</h1>
        <p3>{date}</p3>
        <br />
        <br />
        <img alt="" src={image} />
        <p2>{description}</p2>
      </div>
    );
  };

  const latestNews = [
    {
      title: "Right of Permanent Resident fee increased",
      image: Right,
      path: "/latest-news/right-of-permanent-resident-fee-increased",
    },
    {
      title:
        "Averaged Language Scores now Accepted under Express Entry for Disabled Candidates",
      image: Averaged,
      path: "/latest-news/averaged-language-scores-now-accepted-under-express-entry-for-disabled-candidates",
    },
    {
      title: "Working hours Increased for Students in Canada",
      image: Working,
      path: "/latest-news/working-hours-increased-for-students-in-canada",
    },
  ];
  const LatestNews = ({ title, image, path }) => {
    return (
      <div className="post-card-recent">
        <a href={path}>
          <img alt="" src={image} />
        </a>
        <div className="title-recent">
          <a href={path}>
            <h1 className="line-clamp-2">{title}</h1>
          </a>
          <p2
            style={{
              color: "#009889",
              display: "flex",
              gap: "4px",
              fontSize: "small",
            }}
          >
            Know more{" "}
            <IoIosArrowDroprightCircle style={{ alignSelf: "center" }} />
          </p2>
        </div>
      </div>
    );
  };

  return (
    <>
      <div style={{ background: "#FFF" }}>
        <div className="AboutUsHeader">
          <p className="link">
            <a href="/">Home</a>
            {" > "}
            <a href="/latest-news">Newsroom</a>
            {" > "}French Proficiency Leads Category-Based Selections
          </p>
          <div className="header-content">
            <h1 className="heading-about">Welcome to Our Newsroom</h1>
          </div>
        </div>
        <div className="mt-[2.5rem]">
          <div className="left-blog">
            {vw > 1250 && (
              <div>
                <h1 className="title-heading">Recent News</h1>
                <div className="blog-card-container">
                  <div className="blog-card-recent">
                    {latestNews.map((post, index) => (
                      <LatestNews key={index} {...post} />
                    ))}
                  </div>
                </div>
              </div>
            )}

            <div className="blog-cards-container">
              <div className="blog-cards">
                {innerPosts.map((post, index) => (
                  <InnerBlog key={index} {...post} />
                ))}
              </div>
            </div>
          </div>
          <div className="similar-blog">
            <div className="similar-div-heading">
              <h1 className="title-heading">Similar News</h1>
              <a href="/latest-news">All Articles</a>
            </div>
            <br />
          </div>
          <div className="news-card-container">
            <div className="news-card-upper">
              {newsData.map((news) => (
                <a key={news.id} href={news.path}>
                  <div className="news-upper-card">
                    <img
                      style={{ cursor: "pointer" }}
                      alt=""
                      src={news.image}
                    />
                    <div className="content-card">
                      <div>
                        <p3>{news.date}</p3>

                        <a href={news.path}>
                          <h1>{news.title}</h1>
                        </a>

                        <p2>{news.description} </p2>
                      </div>
                      <a
                        style={{
                          display: "flex",
                          gap: "0.3rem",
                        }}
                        href={news.path}
                      >
                        Read more{" "}
                        <IoIosArrowDroprightCircle
                          style={{ alignSelf: "center" }}
                        />
                      </a>
                    </div>
                  </div>
                </a>
              ))}
            </div>
          </div>
        </div>
        {showBackToTop && (
          <div
            className={makeDivRelative ? "relativeDiv" : "backToTop"}
            onClick={scrollToTop}
          >
            BACK TO TOP
          </div>
        )}
      </div>
      <div ref={bubbleRef}></div>
    </>
  );
};

export default FrenchProficiency;
